@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  height: 30px;
}

.ng-select.ng-select-single .ng-select-container {
  width:100%;
  background-color: #fff;
  border-width: 1px;
  border-color: #cbd5e0;
    border-radius: 0.25rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  
}


.ng-select .ng-arrow-wrapper .ng-arrow {
  height: 12px !important;
  width: 12px !important;
  position: relative;
  background: url(/assets/svgs/select-arrow.svg) no-repeat right;
  margin-left: 15px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: none !important;
}

.ng-select .ng-arrow-wrapper {
  width: 45px;
  padding-right: 5px;
}
.add-textfield {
  padding: 5px 5px 5px 15px;
  height: 45px;
  border-radius: 4px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, .1);
  border: 1px solid #c8c8c8;
  background-color: #fff;
  font-size: 16px
}

.add-w-full {
  width: 100%
}

.add-form-label {
  font-family: Whitney-Book;
  font-size: 16px;
  color: #212121;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600
}

.add-form-input {
  width: 90%;
  display: inline-flex
}

.add-only-help{
  display: inline-flex;
}

.add-form-help {
  margin: 10px 0 10px 15px
}

.add-mb-15 {
  margin-bottom: 15px
}

.add-form-body {
  padding: 20px 63px 32px 31px;
  border-radius: .25rem;
  border: 1px solid #e2e8f0;
  max-width: 700px;
  margin-top: 30px
}

@media only screen and (max-width:960px) {

  .add-form-input,
  .add-form-label {
    width: 100%
  }

  .add-form-body {
    padding: 10px 20px;
    border: none;
    margin-top: 5px
  }
}

.add-title {
  font-family: Whitney-Book;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff6319;
  margin-bottom: 15px
}

.add-btn {
  height: 45px;
  padding: 6px;
  border-radius: .25rem;
  width: 100%;
  font-size: 18px;
  margin-top: 10px
}

.add-btn:Hover {
  cursor: pointer
}

.add-btn-primary {
  background-color: #ff6319;
  color: #fff
}

.add-btn-cancel {
  color: #ff6319;
  background-color: #fff;
  border: 1px solid
}

@media only screen and (max-width:960px) {
  .add-btn-cancel {
    margin-top: 15px
  }

  .add-btns {
    display: block;
    margin-left: 0;
    width: 100%
  }
}

.mt-4 {
  margin-top: 1rem
}

*,
::after,
::before {
  box-sizing: inherit;
  border: 0 solid #e2e8f0
}

.add-btns {
  margin-top: 20px
}

.input-invalid-text {
  color: #ff6319;
  font-size: 15px;
  margin-top: 6px
}

.add-group-checkboxes {
  display: flex
}

.add-group-checkboxes label {
  margin-top: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, .1);
  border: 1px solid #c8c8c8;
  padding: 10px;
  margin-right: 10px;
  font-weight: 400
}

.add-group-checkboxes input[type=radio] {
  display: none
}

.add-group-checkboxes input:checked+label {
  background-color: #007ac9;
  color: #fff !important;
  border-color: #007ac9
}

.add-group-checkboxes .check {
  visibility: hidden
}

.add-group-checkboxes input:checked+label .check {
  visibility: visible
}

.add-group-checkboxes input.checkbox:checked+label:before {
  content: ""
}

.add-double-checkboxes {
  display: flex
}

.add-double-checkboxes label {
  margin-top: 10px;
  text-align: center;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, .1);
  border: 1px solid #c8c8c8;
  padding: 10px;
  font-weight: 400;
  width: 81px
}

.add-double-checkboxes .left-label {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px
}

.add-double-checkboxes .right-label {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: none
}

.add-double-checkboxes input[type=radio] {
  display: none
}

.add-double-checkboxes input:checked+label {
  background-color: #007ac9;
  color: #fff !important;
  border-color: #007ac9
}

.add-double-checkboxes .check {
  visibility: hidden
}

.add-double-checkboxes input:checked+label .check {
  visibility: visible
}

.add-double-checkboxes input.checkbox:checked+label:before {
  content: ""
}

@media only screen and (max-width:960px) {
  .dis-grid {
    display: grid
  }
}

.max-w-75 {
  max-width: 75px
}

.add-form-help-text {
  margin: 20px 0 0 10px
}

.add-form-help-text:hover {
  cursor: pointer
}

.title-name {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: .9;
  letter-spacing: normal;
  color: #212121
}

.grecaptcha-badge {
  visibility: hidden;
}
